exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-old-js": () => import("./../../../src/pages/about-old.js" /* webpackChunkName: "component---src-pages-about-old-js" */),
  "component---src-pages-architect-designer-js": () => import("./../../../src/pages/architect-designer.js" /* webpackChunkName: "component---src-pages-architect-designer-js" */),
  "component---src-pages-automation-js": () => import("./../../../src/pages/automation.js" /* webpackChunkName: "component---src-pages-automation-js" */),
  "component---src-pages-builders-js": () => import("./../../../src/pages/builders.js" /* webpackChunkName: "component---src-pages-builders-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-curtains-js": () => import("./../../../src/pages/curtains.js" /* webpackChunkName: "component---src-pages-curtains-js" */),
  "component---src-pages-developer-js": () => import("./../../../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-external-range-js": () => import("./../../../src/pages/external-range.js" /* webpackChunkName: "component---src-pages-external-range-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-honeycomb-js": () => import("./../../../src/pages/honeycomb.js" /* webpackChunkName: "component---src-pages-honeycomb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-js": () => import("./../../../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-projects-folia-js": () => import("./../../../src/pages/projects/folia.js" /* webpackChunkName: "component---src-pages-projects-folia-js" */),
  "component---src-pages-projects-voyager-js": () => import("./../../../src/pages/projects/voyager.js" /* webpackChunkName: "component---src-pages-projects-voyager-js" */),
  "component---src-pages-roller-blinds-js": () => import("./../../../src/pages/roller-blinds.js" /* webpackChunkName: "component---src-pages-roller-blinds-js" */),
  "component---src-pages-roman-blinds-js": () => import("./../../../src/pages/roman-blinds.js" /* webpackChunkName: "component---src-pages-roman-blinds-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-shutters-js": () => import("./../../../src/pages/shutters.js" /* webpackChunkName: "component---src-pages-shutters-js" */),
  "component---src-pages-styledetails-js": () => import("./../../../src/pages/styledetails.js" /* webpackChunkName: "component---src-pages-styledetails-js" */),
  "component---src-pages-term-sale-js": () => import("./../../../src/pages/term-sale.js" /* webpackChunkName: "component---src-pages-term-sale-js" */),
  "component---src-pages-venetians-js": () => import("./../../../src/pages/venetians.js" /* webpackChunkName: "component---src-pages-venetians-js" */)
}

